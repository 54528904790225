import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FornecedorService {
    origemPortalDeCotacoes$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    codigoDaEmpresa$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    especificoPoiesis$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    constructor(private _router: Router) {
        this.codigoDaEmpresa$.subscribe((r) => {
            // POIESIS
            if (r == 2308) {
                this.especificoPoiesis$.next(true);
            }
        });
    }

    origemPortalDeCotacoes(b: boolean) {
        this.origemPortalDeCotacoes$.next(b);
    }
    codigoDaEmpresa(codempresa: number): void {
        this.codigoDaEmpresa$.next(codempresa);
    }

    fornecedorCadastro(): void {
        this._router.navigate(['fornecedor/cadastro']);
    }
    portalDeCotacaoLogin(): void {
        this._router.navigate(['./']);
    }
}
