import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { FornecedorAuthStoreService } from '../store/fornecedor-auth.store.service';

@Injectable()
export class FornecedorAuthService {
    cnpj$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    data$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    valid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    error$: Observable<any>;
    message$: Observable<any>;
    isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    constructor(private _storeService: FornecedorAuthStoreService) {
        this.error$ = this._storeService.getError();
        this.message$ = this._storeService.getMessage();

        this._storeService.getCnpj().subscribe((r) => this.cnpj$.next(r));
        this._storeService.getData().subscribe((r) => this.data$.next(r));
        this._storeService.getIsValid().subscribe((r) => this.valid$.next(r));

        this._storeService
            .getIsProcessing()
            .subscribe((r) => this.isProcessing$.next(r));
    }

    limpar(): void {
        this._storeService.dispatchLimpar();
    }

    verificarCnpj(cnpj: string) {
        if (!cnpj) {
            return;
        }

        this._storeService.dispatchVerificarCnpj(cnpj);
    }
}
