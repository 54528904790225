import { NgRxAction } from '../../../store/ngrx-action';

export enum FornecedorAuthActions {
    VERIFICAR_CNPJ = '[FornecedorAuth] Verificar CNPJ',

    CNPJ_JA_CADASTRADO = '[FornecedorAuth] CNPJ ja cadastrado',
    CNPJ_NAO_CADASTRADO = '[FornecedorAuth] CNPJ não cadastrado',

    LIMPAR = '[FornecedorAuth] Limpar',
    ERRO = '[FornecedorAuth] Erro',
}

export class VerificarCnpjAction extends NgRxAction<any> {
    type = FornecedorAuthActions.VERIFICAR_CNPJ;
}

export class CnpjJaCadastradoAction extends NgRxAction<any> {
    type = FornecedorAuthActions.CNPJ_JA_CADASTRADO;
}
export class CnpjNaoCadastradoAction extends NgRxAction<any> {
    type = FornecedorAuthActions.CNPJ_NAO_CADASTRADO;
}

export class LimparAction extends NgRxAction<any> {
    type = FornecedorAuthActions.LIMPAR;
}
export class ErroAction extends NgRxAction<any> {
    type = FornecedorAuthActions.ERRO;
}

export type FornecedorAuthAction =
    | VerificarCnpjAction
    | CnpjJaCadastradoAction
    | CnpjNaoCadastradoAction
    | LimparAction
    | ErroAction;
