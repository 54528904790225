import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';

import { StoreService } from '../../../store/app-store.service';
import { AppState } from '../../../store/app.reducer';
import * as act from './fornecedor-auth.actions';
import {
    FORNECEDOR_AUTH_STORE,
    FornecedorAuthState,
} from './fornecedor-auth.state';

@Injectable()
export class FornecedorAuthStoreService extends StoreService {
    protected readonly STATE = FORNECEDOR_AUTH_STORE;

    constructor(protected store: Store<AppState>) {
        super();
    }

    dispatchVerificarCnpj(cnpj: string) {
        this.dispatchAction(new act.VerificarCnpjAction(cnpj));
    }

    dispatchCnpjJaCadastrado(fornec: any) {
        this.dispatchAction(new act.CnpjJaCadastradoAction(fornec));
    }

    dispatchCnpjNaoCadastrado(cnpj: string) {
        this.dispatchAction(new act.CnpjNaoCadastradoAction(cnpj));
    }

    dispatchLimpar() {
        this.dispatchAction(new act.LimparAction());
    }

    getCnpj() {
        return this.storeSelectFeatureState().pipe(
            select((state: FornecedorAuthState) => state.cnpj)
        );
    }

    getIsValid() {
        return this.storeSelectFeatureState().pipe(
            select((state: FornecedorAuthState) => state),
            // filter((state: FornecedorAuthState) => ),
            // map((state: FornecedorAuthState) => state.podeInserir)
            map(
                (state: FornecedorAuthState) =>
                    state.cnpj &&
                    state.podeInserir == true &&
                    state.isProcessing == false
            )
        );
    }

    getData() {
        return this.storeSelectFeatureState().pipe(
            select((state: FornecedorAuthState) => state.data)
            // filter(r => r)
        );
    }

    getIsProcessing() {
        return this.storeSelectFeatureState().pipe(
            select((state: FornecedorAuthState) => state.isProcessing),
            filter((r) => r == true || r == false)
        );
    }

    getError() {
        return this.storeSelectFeatureState().pipe(
            select((state: FornecedorAuthState) => state.error),
            filter((r) => r)
        );
    }

    getMessage() {
        return this.storeSelectFeatureState().pipe(
            select((state: FornecedorAuthState) => state.message),
            filter((r) => r)
        );
    }
}
