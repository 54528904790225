export const FORNECEDOR_AUTH_STORE = 'FORNECEDOR_AUTH_STORE';

export interface FornecedorAuthState {
    cnpj: any;
    podeInserir: boolean;
    data: any;
    error: any;
    isProcessing: boolean;
    message: any;
}

export const fornecedorAuthInitialState: FornecedorAuthState = {
    cnpj: undefined,
    podeInserir: false,
    data: undefined,
    error: undefined,
    isProcessing: undefined,
    message: undefined,
};
